import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import SEO from "../components/seo"
import CenterHomeBanner from "../components/centerHomeBanner"
import CenterBreadCrumbs from "../components/centerBreadCrumbs"

const JustKiddInnToo = () => {
  const data = useStaticQuery(graphql`
    query {
      jki: file(relativePath: { eq: "jki2-slide1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1068) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <SEO title="Just Kidd Inn Too" />

      <CenterHomeBanner image={data.jki.childImageSharp.fluid} />

      <CenterBreadCrumbs
        centerName="Just Kiddinn Too"
        centerUrl="/justkiddinntoo/"
      />

      <div class="container-fluid horizontal-section-container clearfix">
        <div class="row">
          <div class="col-sm-12">
            <div
              id="main-page-content"
              class="section-container main-page-content clearfix"
            >
              <div class="section-content">
                <h1 class="page_title">Welcome</h1>

                <h4>Just Kidd Inn Too Ltd</h4>

                <p>
                  46 Ambler Ave, Glen Eden – Hours of operation 7.30am – 5.00pm
                </p>

                <p>
                  Founded in 1994, caters for children from 0-5 years, with a
                  license for 35 children / 15 under 2’s.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid horizontal-section-container clearfix">
        <div class="row">
          <div class="col-sm-10">
            <div id="footer-contact-us" class="section-container clearfix">
              <span class="section-header footer-header">
                <h5 class="text">Contact Us</h5>
              </span>

              <div class="section-content clearfix">
                <div class="vertical-simple-list contact-list">
                  <div class="col-md-6">
                    <div class="item clearfix">
                      <div class="item-content">
                        <div class="icon">
                          <span class="glyphicon glyphicon-map-marker"></span>
                        </div>
                        <h6 class="title">
                          46 Ambler Ave, Glen Eden
                          <br />
                          Hours of operation 7.30am – 5.00pm
                        </h6>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="item clearfix">
                      <div class="item-content">
                        <div class="icon">
                          <span class="glyphicon glyphicon-earphone"></span>
                        </div>
                        <h6 class="title">
                          <a href="tel:09 8181432">(+649) 09 8181432</a>
                        </h6>
                      </div>
                    </div>

                    <div class="item clearfix">
                      <div class="item-content">
                        <div class="icon">
                          <span class="glyphicon glyphicon-envelope"></span>
                        </div>
                        <h6 class="title">
                          <a
                            href="mailto:kiddinn@xtra.co.nz"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            kiddinn@xtra.co.nz
                          </a>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default JustKiddInnToo
